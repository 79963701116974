<template>
  <div ref="mobile" class="mobile-container" :style="{
    '--var-pannel-height-1': heights[0] + 'vh',
    '--var-pannel-height-2': heights[1] + 'vh',
    '--var-pannel-height-max-1': maxHeights[0] + 'vh',
    '--var-pannel-height-max-2': maxHeights[1] + 'vh'
  }">
    <div class="inner" ref="inner">
      <!--顶部header-->
      <div class="logo-header-row">
        <div class="logo-1-row">
          <img src="/appinstall/pictures/header-logo-3.png">
        </div>
         <!--分割-->
        <div class="divider-col-row"><div class="divider-inner"></div></div>

        <div class="logo-2-row">
          <img src="/appinstall/pictures/header-logo-4.png">
        </div>
      </div>
      
      <!--分割-->
      <div class="divider-row"><div class="divider-inner"></div></div>

      <!--展示区域-->
      <div class="show-area-2">
        <div class="logo-row">
          <img src="/appinstall/pictures/title-logo.png">
          <div class="content">{{ setting.title }}</div>
        </div>

        <div class="row-1 row-target">{{ setting.rows[0]}}</div>
        <div class="row-2 row-target">{{ setting.rows[1]}}</div>

        <div class="img-row">
          <img src="/appinstall/pictures/show-area-2.png">
        </div>
        <div class="shadow-row"></div>
      </div>

      <!--提示信息区域-->
      <div class="ins-title-row-1">{{ setting.description }}</div>
      <!--按钮区域-->
      <div class="button-area">
        <div @click="download('az')"  class="button-target" :class="appInfo.az ? '' : 'opacity-control'">
          <div class="img-row">
            <img src="/appinstall/pictures/android-logo-dark.png">
          </div>
          <div class="tip-row">Android</div>
          <div class="version-row">{{ appInfo.az ? appInfo.az.displayVer : '--' }}</div>
        </div>

        <div @click="download('ios')" class="button-target" :class="appInfo.ios ? '' : 'opacity-control'">
          <div class="img-row">
            <img src="/appinstall/pictures/ios-logo-dark.png">
          </div>
          <div class="tip-row">IOS</div>
          <div class="version-row">{{ appInfo.ios ? appInfo.ios.displayVer : '--' }}</div>
        </div>
      </div>
      
      <div class="ins-title-row-2">App安装说明</div>

      <div class="introduction-row az-pannel" ref="pannel1">
        <div class="pannel-header" ref="header1" @click="open(0)">
          <div class="barriar-row"></div>

          <div class="tip-row" :class="openStatu !== 0 ? 'opacity-control' : ''">
            <div class="img-row">
              <img src="/appinstall/pictures/android-logo-dark.png">
            </div>
            <div class="content-row">Android安装说明</div>
          </div>

          <div class="arrow-row" :class="openStatu !== 0 ? 'rotate-arrow' : ''">
            <img src="/appinstall/pictures/arrow-dark.png">
          </div>
        </div>


        <div class="pannel-content">

          <div class="divider-row"><div class="divider-inner"></div></div>

          <div class="description-row">
            <div class="problems-row">{{ setting.instructions[0].problems }}</div>
            <div class="title-row">解决方法：</div>
            <div class="solution-row">{{ setting.instructions[0].solution }}</div>
          </div>

          <div class="img-row"
            v-for="(item1, index1) in setting.instructions[0].pictures"
            :key="`az-img-${index1}`">
            <img :src="item1">
          </div>
        </div>
      </div>

      <div class="introduction-row ios-pannel" ref="pannel2">
        <div class="pannel-header" ref="header2"  @click="open(1)">
          <div class="barriar-row"></div>

          <div class="tip-row" :class="openStatu !== 1 ? 'opacity-control' : ''">
            <div class="img-row">
              <img src="/appinstall/pictures/ios-logo-dark.png">
            </div>
            <div class="content-row">IOS安装说明</div>
          </div>

          <div class="arrow-row" :class="openStatu !== 1 ? 'rotate-arrow' : ''">
            <img src="/appinstall/pictures/arrow-dark.png">
          </div>
        </div>

        <div class="pannel-content">

          <div class="divider-row"><div class="divider-inner"></div></div>
          

          <div class="description-row">
            <div class="problems-row">{{ setting.instructions[1].problems }}</div>
            <div class="title-row">解决方法：</div>
            <div class="solution-row">{{ setting.instructions[1].solution }}</div>
          </div>

          <div class="img-row"
            v-for="(item1, index1) in setting.instructions[1].pictures"
            :key="`az-img-${index1}`">
            <img :src="item1">
          </div>
        </div>
      </div>

      <div class="contact-row">
        <div class="content-row">技术支持：itsiiri@siiri.cn</div>
      </div>

      <div class="rights-row">
        <div class="content-row">Copyright 2023 ©国机工业互联网研究院有限公司. All rights reserved.</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store.js'
export default {
  name: '',
  methods: {
    open(e) {
      if (this.heights[e] === 6) {
        this.openStatu = e
        this.heights[0] = 6
        this.heights[1] = 6
        this.heights[e] = setting.heights[e]
      } else {
        this.heights[e] = 6
        this.openStatu = -1
      }

      // console.log(this.$refs.inner.scrollTop)

      this.$forceUpdate()

      this.$nextTick(() => {
        // console.log(this.$refs.pannel1.offsetTop)
        setTimeout(() => {
          if (this.openStatu === -1) {
            this.$refs.mobile.scrollTo({
              top: this.$refs.pannel1.offsetTop,
              behavior: "smooth"
            })
          } else if (this.openStatu === 0) {
            this.$refs.mobile.scrollTo({
              top: this.$refs.pannel1.offsetTop,
              behavior: "smooth"
            })
          } else {
            this.$refs.mobile.scrollTo({
              top: this.$refs.pannel2.offsetTop,
              behavior: "smooth"
            })
          }
        }, 400)
      })
      
    },
    download(e) {
      if (!this.appInfo[e]) {
        return
      } else if (this.loading) {
        return
      }
      store.downloadFile({platform: e})
    }
  },
  created() {
    this.setting = setting
    let timer  = null
    timer = setInterval(() => {
      let res = store.getAppInfo()
      if (res !== null) {
        clearInterval(timer)
        Object.assign(this.appInfo, store.getAppInfo())
        this.loading = false
        this.$forceUpdate()
      }
    }, 400)
  },
  mounted() {
    let option = {
      rootMargin: '0% 0% -90% 0%',
      threshold:  [1]
    }
    this.observer = new IntersectionObserver( 
      // callback
      ([e]) => {
        e.target.classList.toggle("is-pinned", e.intersectionRatio === 1)
      },
      option
    )
    this.observer.observe(this.$refs.header1)
    this.observer.observe(this.$refs.header2)
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  props: {},
  data() {
    return {
      setting: {},
      heights: [6 , 6],
      openStatu: -1,
      observer: null,
      maxHeights: [6, 6],
      appInfo: {},
      loading: true
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
</style>


<style lang="scss" scoped>
@import './styles/base-layout.scss';
@import './styles/detail.scss';
@import '@/assets/style/animation.scss';
img {
  width: 100%;
  height: auto;
  display: block;
}

.az-pannel {
  transition: all .8s ease-in-out;
  min-height: var(--var-pannel-height-1);
  // max-height: calc(var(--var-pannel-height-1) + 1vh);
  max-height: var(--var-pannel-height-max-1);
}
.ios-pannel {
  transition: all .5s ease-in-out;
  min-height: var(--var-pannel-height-2);
  max-height: var(--var-pannel-height-max-2);
}
</style>
