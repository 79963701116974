<template>
  <div class="desktop-mask">
    <div ref="desktopPage" class="desktop-container" :style="{
      '--var-radius': radius + 'px'
    }">
      <div class="desktop-back-1" ref="pageSection1">
        <!--顶部logo栏-->
        <div class="logo-header common-target">
          <div class="logo-1-row">
            <img src="/appinstall/pictures/header-logo-1.png">

            <div class="divider"></div>
          </div>

          <div class="logo-2-row"><div class=""><img src="/appinstall/pictures/header-logo-2.png"></div></div>
        </div>

        <!--左侧展示区域-->
        <div class="show-area-1 common-target">
          <img src="/appinstall/pictures/show-area-1.png">
        </div>

        <!--右侧标题logo-->
        <div class="title-area-1 common-target">
          <div class="logo-area"><img src="/appinstall/pictures/title-logo.png"></div>
          <div class="content-area">{{ setting.title }}</div>
        </div>

        <!--右侧标题slogan-->
        <div class="title-content common-target">
          <img src="/appinstall/pictures/slogan-1.png">
        </div>

        <!--按钮区域-->
        <div class="btn-area common-target">
          <div class="btn-1 btn-box qr-btn-box">
            <div class="content-area">
              <div class="tip-row">
                <img src="/appinstall/pictures/android-logo.png">
                <div>Android</div>
              </div>
              <div class="img-row">
                <vue-qr  
                  ref="qrCode"   
                  :text="appUrl"
                  :logoScale="40"
                />  
              </div>
            </div>

            <div class="qr-btn-mask"></div>

          </div>

          <div class="btn-2 btn-box qr-btn-box">
            <div class="content-area">
              <div class="tip-row">
                <img src="/appinstall/pictures/ios-logo.png">
                <div>iOS</div>
              </div>
              <div class="img-row">
                <vue-qr  
                  ref="qrCode"   
                  :text="appUrl"
                  :logoScale="40"
                />  
                <!--logoSrc="/appinstall/pictures/title-logo.png"-->
                <!-- <img class="img-row" src="/appinstall/pictures/qr-code.png"> -->
              </div>
            </div>
          </div>

          <div @click="download('win')" ref="buttonPresident"  v-resize="resize" class="btn-3 btn-box common-btn-box">
            <div class="tip-row">
              <img src="/appinstall/pictures/win-logo.png">
              <div>Windows</div>
            </div>
          </div>

          <div @click="download('mac')" class="btn-4 btn-box common-btn-box">
            <div class="tip-row">
              <img src="/appinstall/pictures/ios-logo.png">
              <div>macOS</div>
            </div>
          </div>
        </div>

        <!--安装说明按钮-->
        <div class="instructions-btn-area common-target" @click="scrollToIns">
          <div class="inner-instructions-btn">
            <div class="tip-row">安装说明</div>
          <div class="img-row"><img class="desktop-backgroud-1" src="/appinstall/pictures/arrow.png"></div>
          </div>
        </div>

        <!--背景图-->
        <img class="desktop-backgroud" src="/appinstall/pictures/desktop-back-1.png">
      </div>

      <div class="desktop-back-2" ref="pageSection2">

        <div class="title-area-2 common-target">应用安装说明</div>

        <div class="tab-row common-target" v-if="isShow">
          <div class="tab-inner">
            <div :class="active === 1 ? 'tab-selected-item' : 'tab-item'"
              @click="tabClick(1)">
              <div class="tip-row">
                <img :src="active === 1 ?
                  '/appinstall/pictures/android-logo-active.png' :
                  '/appinstall/pictures/android-logo-dark.png'">
                <div class="tip-content">
                  Android
                </div>
              </div>
              <div class="line-row">
                <div class="line-target"></div>
              </div>
            </div>

            <div :class="active === 2 ? 'tab-selected-item' : 'tab-item'"
              @click="tabClick(2)">
              <div class="tip-row">
                <img :src="active === 2 ?
                  '/appinstall/pictures/ios-logo-active.png' :
                  '/appinstall/pictures/ios-logo-dark.png'">
                <div class="tip-content">
                  iOS
                </div>
              </div>
              <div class="line-row">
                <div class="line-target"></div>
              </div>
            </div>

            <div :class="active === 3 ? 'tab-selected-item' : 'tab-item'" 
              @click="tabClick(3)">
              <div class="tip-row">
                <img :src="active === 3 ?
                  '/appinstall/pictures/win-logo-active.png' :
                  '/appinstall/pictures/win-logo-dark.png'">
                <div class="tip-content">
                  Windows
                </div>
              </div>
              <div class="line-row">
                <div class="line-target"></div>
              </div>
            </div>


            <div :class="active === 4 ? 'tab-selected-item' : 'tab-item'"
              @click="tabClick(4)">
              <div class="tip-row">
                <img :src="active === 4 ?
                  '/appinstall/pictures/ios-logo-active.png' :
                  '/appinstall/pictures/ios-logo-dark.png'">
                <div class="tip-content">
                  macOS
                </div>
              </div>
              <div class="line-row">
                <div class="line-target"></div>
              </div>
            </div>

          </div>

        </div>

        <div class="tab-area common-target" v-if="isShow">
          <instruction :index="active" @indexChange="indexChange"></instruction>
        </div>

        <div class="contact-row common-target">
          <div class="content-row">技术支持：itsiiri@siiri.cn</div>
        </div>

        <div class="rights-row common-target">
          <div class="content-row">Copyright 2023 ©国机工业互联网研究院有限公司. All rights reserved.</div>
        </div>

        <img class="desktop-backgroud" src="/appinstall/pictures/desktop-back-2.png">
      </div>
      <!-- <a style="display: none" :href="downloadUrl" ref="download"></a> -->
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
import instruction from './modules/instruction.vue'
import store from '@/store.js'
export default {
  name: '',
  methods: {
    // 监听组件的高度变化
    resize(e) {
      // console.log('resize', e)
      this.$nextTick(() => {
        // 重设按钮圆角
        let height = this.$refs.buttonPresident.clientHeight
        this.radius = height / 2
        // 记录页面高度
        // this.pageHeight = this.$refs.pageSection1.clientHeight
        //   + this.$refs.pageSection2.clientHeight
        // // console.log(this.pageHeight)
        // // console.log(this.pageHeight)
        // if (this.getWindowsHeight() / this.pageHeight > 0.6) {
        //   this.isShow = true
        // }
        // console.log('页面高度： ', this.pageHeight)
        // console.log('视口高度: ', this.getWindowsHeight())
      })
    },
    scrollToIns() {
      // console.log(this.$refs.desktopPage.scrollTop)
      this.$refs.desktopPage.scroll({
        top: this.$refs.pageSection1.clientHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
    tabClick(index) {
      this.active = index
    },
    slideListener() {
      // console.log('desktop: ', this.$refs.desktopPage.scrollTop)
      // 两种情况浮现
      // 一种为视口高度大于大于页面高度的50%
      // 一种为滚动距离大于页面的15%
    },
    getWindowsHeight() {
      return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    },
    indexChange(e) {
      this.active = e
    },
    download(e) {
      store.downloadFile({platform: e})
    }
  },
  created() {
    this.setting = setting
    // console.log(this.setting)
  },
  mounted () {
    // 初始化数据
  //  this.$refs.desktopPage.addEventListener('scroll', this.slideListener)
  //  this.pageHeight = this.$refs.desktopPage.scrollHeight
  },
  beforeDestroy () {
   
  },
  props: {},
  data() {
    return {
      setting: {},
      radius: 41,
      active: 1,
      viewheight: 0,
      pageHeight: 0,
      isShow: true,
      downloadUrl: '',
      appUrl: setting.appUrl
    }
  },
  components: { instruction, VueQr }
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
@import './styles/first-section.scss';
@import './styles/second-section.scss';
@import './styles/base-layout.scss';
img {
  width: 100%;
  height: auto;
  display: block;
}

</style>
