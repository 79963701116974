<template>
  <div class="mask-container">
    <div class="image-row">
      <img src="/appinstall/pictures/title-logo.png">
    </div>
    <div class="title-row">
      {{ setting.title  }}
    </div>
    <div class="circle-row" v-if="!showMobile"></div>
    <div class="content-row">
      {{ setting.welcome_content  }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'mask-vue',
  methods: {},
  created() {},
  props: {
    showMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      setting: setting
    }
  },
  components: {}
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
@import '@/assets/style/animation.scss';
.mask-container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: black;
  animation: slide-out 1s forwards 2s, destroy .1s forwards 3s;
  // background-image: url('/appinstall/pictures/desktop-back-1.png');
  // animation: slide-out 1s;
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  // opacity: 0.1;

  & > .image-row {
    width: 10vh;
    height: 10vh;
    margin-bottom: 2vh;
    opacity: 0;

    animation: jump-in 1s forwards .5s;

    & > img {
      width: 100%;
      height: auto;
    }
  }

  & > .title-row {
    color: white;
    font-size: 2.5vh;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    margin-bottom: 3vh;
  }

  & > .circle-row {
    display: block;
    border: 0.2vmax solid white;
    border-top-color: black;
    border-radius: 999px;
    // height: 50 vMin;
    // width: 50 vMin;
    height: 1.3vmax;
    width: 1.3vmax;
    margin-bottom: 1vh;
    opacity: 0;

    // slide-in-top .3s forwards .4s,
    animation: slide-in-top .3s forwards .4s,
    rotate-unline 0.8s infinite .6s cubic-bezier(0.15, 0.45, 0.65, 1);
    // animation: rotate-unline 0.8s infinite .6s ease-in-out;
  }
  & > .content-row {
    color: white;
    font-size: 2.0vh;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 400;
    opacity: 0;
    animation: slide-in-top .4s forwards 1s;
  }
}
</style>
