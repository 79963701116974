<template>
  <div class="wechat-mask-container">
    <img src="/appinstall/pictures/wechat-mask.png">
  </div>
</template>

<script>
export default {
  name: '',
  methods: {},
  created() {},
  props: {},
  data() {
    return {}
  },
  components: {}
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
.wechat-mask-container {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 998;
  background: rgba($color: #000000, $alpha: 0.7);
  & > img {
    width: 100%;
  }
}
</style>
