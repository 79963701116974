<template>
  <!--外部遮罩容器-->
  <div class="instruction-mask" v-resize="resize" ref="mask">
    <div class="outer-container" ref="outer">
    <!--画幅-->
      <div class="format-contianer" ref="formatTarget">
        <!--每个单独的说明模块-->
        <div class="instruction-cantainer"
          v-for="(item, index1) in instructions"
          :key="`instructions${index1}`">
          <div class="description-area">
            <div class="problems-row">{{ item.problems }}</div>
            <div class="title-row">解决方法：</div>
            <div class="solution-row">{{ item.solution }}</div>
          </div>
          <div class="pictures-area">
            <img v-for="(src, index2) in item.pictures" :key="`imgrow${index1}${index2}`" :src="src">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  methods: {
    resize() {
      this.noListen= true
      // 重新计算滚动条状态
      this.length = this.$refs.formatTarget.clientWidth
      this.resetScroll(this.index)
    },
    scrollToTarget(e) {
      this.noListen= true
      // console.log(this.$refs.desktopPage.scrollTop)
      this.$refs.outer.scroll({
        top: 0,
        left: (e-1) * this.length / 4,
        behavior: 'smooth'
      })
    },
    resetScroll(e) {
      this.noListen= true
      this.$refs.outer.scroll({
        top: 0,
        left: (e-1) * this.length / 4
      })
    },
    correctScroll() {
      // 0 判断滚动是否由用户触发
      if (this.noListen) {
        // console.log('no listen')
        this.noListen = false
      }
      // console.log(this.$refs.outer.scrollLeft)
      // 1 获取当前滚动距离
      let left = this.$refs.outer.scrollLeft
      let total = this.$refs.outer.scrollWidth
      // 2 计算当前位置
      let postion = left / total
      // 3 计算当前区间
      let start
      if (postion >= 0.5) {
        start = 0.5
      } else if (postion >= 0.25) {
        start = 0.25
      } else {
        start = 0.0
      }
      let end = start + 0.25
      // 4 计算position与哪个端点更近

      // console.log(postion)

      let result1 = postion - start
      let result2 = postion - end

      if (result1 === 0) {
        this.scrollByPosition(start)
      } else if (result2 === 0) {
        this.scrollByPosition(end)
      } else {
        if (Math.abs(result1) < Math.abs(result2)) {
          this.scrollByPosition(start)
        } else {
          this.scrollByPosition(end)
        }
      }

      // console.log('可滚动距离: ', total - this.$refs.mask.scrollWidth)
      // console.log('当前位置: ', left / total)
      // console.log('总宽度: ', this.length)
    },
    scrollByPosition(position) {
      // console.log(position)
      let newIndex = 1
      if (position === 0.0) {
        newIndex = 1
      } else if (position === 0.25) {
        newIndex = 2
      } else if (position === 0.5) {
        newIndex = 3
      } else {
        newIndex = 4
      }
      this.scrollToTarget(newIndex)
      this.nowIndex = newIndex
      this.$emit('indexChange', newIndex)
    }
  },
  created() {
    this.instructions = setting.instructions
  },
  mounted() {
    let timeoutHdr = null
    // console.log(this.$refs.formatTarget.clientWidth)

    // 回正工具
    this.$refs.outer.addEventListener('scroll', () => {
      // 开始时先清除旧的计时器
      window.clearTimeout(timeoutHdr)
      timeoutHdr = window.setTimeout(() => {
        // 判断是否为函数触发
        if (this.noListen) {
          // 重置状态
          // console.log('no listen')
          this.noListen = false
          return
        }
        this.correctScroll()
      }, 40)
    })
  },
  beforeDestroy() {
    this.$refs.outer.removeEventListener('scroll', this.correctScroll)
  },
  watch: {
    index: function(val) {
      if (this.nowIndex === val) {
        return
      }
      this.nowIndex = val
      this.scrollToTarget(val)
    }
  },
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      instructions: {},
      length: 0,
      nowIndex: 0,
      noListen: true
    }
  },
  components: {}
}
</script>

<style lang="scss">
@import '@/assets/style/animation.scss';
</style>

<style lang="scss" scoped>
.instruction-mask {
  height: 100%;
  width: 80%;
  overflow: hidden;
  margin:auto;
}
.outer-container {
  width: 100%;
  height: calc(100% + 17px);
  overflow-y: hidden;
  overflow-x: auto;
}

.format-contianer {
  // width: auto;
  width: 400%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  // scroll-snap-type: x mandatory;
}

.instruction-cantainer {
  width: 100%;
  height: 100%;
  margin: 0 3%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  // scroll-snap-align: start;
}

.description-area {
  width: 100%;
  height: auto;
  & > * {
    text-align: left;
  }
  & > .problems-row {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 550;
    color: rgba(0,0,0,0.9);
    font-size: 1vw;
  }

  & > .title-row {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 550;
    color: rgba(0,0,0,0.6);
    font-size: 1vw;

    margin: {
      top: 5%;
      bottom: 2%;
    }
  }

  & > .solution-row {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 550;
    color: rgba(0,0,0,0.9);
    font-size: 1vw;
  }
}

.pictures-area {
  margin-top: 2%;
  flex-grow: 1;
  width: 100%;
  // background: silver;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  & > img {
    width: 0;
    flex-grow: 1;
    height: auto;
    margin: 2%;
    max-width: 20%;
  }
}
</style>
