<template>
  <div class="home-container" ref="home" v-resize="resize">
    <waiting-mask v-if="showMask" :show-mobile="showMobile"></waiting-mask>
    <wechat-mask v-if="showWechatMask" />
    <div class="component-area">
      <Desktop v-if="!showMobile"></Desktop>
      <Mobile v-else></Mobile>
    </div>
    <!-- <div class="tip-row" v-show="showTip">您的设备宽度较低，建议切换为移动端视图</div> -->
  </div>
</template>

<script>
import Desktop from '@/views/Desktop/index'
import Mobile from '@/views/Mobile/index'
import WaitingMask from './modules/waiting-mask.vue'
import WechatMask from './modules/wechat-mask.vue'
export default {
  name: 'home-index',
  methods: {
    resize(e) {
      // console.log(e)
    }
  },
  created() {
    // getLink().then(res => {
    //   console.log(res)
    // })
  },
  mounted() {
    // console.log('用户代理', navigator.userAgent)
    // console.log('是否为移动设备： ', /Android|webOS|iPhone|iPod|BlackBerry|HUAWEI/i.test(navigator.userAgent))
    // 1. 通过请求头的用户代理判断请求来源

    if (/Android|webOS|iPhone|iPod|BlackBerry|HUAWEI/i.test(navigator.userAgent)) {
      // 为真，则说明通过手机访问
      this.showMobile = true
      // this.showMask = false
    }
    if (/micromessenger/i.test(navigator.userAgent)) {
      this.showWechatMask = true
    }

    let that = this
    setTimeout(function () {
      that.showMask = false
      // console.log('销毁！')
    }, 2000)
  },
  props: {},
  data() {
    return {
      height: 0,
      currentScroll: 0,
      showMobile: false,
      showTip: false,
      showMask: true,
      showWechatMask: false
    }
  },
  components: { Desktop, Mobile, WaitingMask, WechatMask }
}
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
.home-container {
  width: 100%;
  height: 100%;

  overflow-y: hidden;
  overflow-x: auto;
  // position: relative;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  & > .tip-row {
    position: fixed;
    height: 10%;
    width: 100%;
    margin: 0;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.3);
    font-family: PingFangSC-Light, PingFang SC;
    font-size: 1.8vw;
    text-align: left;
    color: rgba(255,255,255,0.9);
    z-index: 999;
  }

  & > .component-area {
    width: 100%;
    height: 100%;
    position: relative;
  }
}
</style>
