var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"desktop-mask"},[_c('div',{ref:"desktopPage",staticClass:"desktop-container",style:({
    '--var-radius': _vm.radius + 'px'
  })},[_c('div',{ref:"pageSection1",staticClass:"desktop-back-1"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"title-area-1 common-target"},[_vm._m(2),_c('div',{staticClass:"content-area"},[_vm._v(_vm._s(_vm.setting.title))])]),_vm._m(3),_c('div',{staticClass:"btn-area common-target"},[_c('div',{staticClass:"btn-1 btn-box qr-btn-box"},[_c('div',{staticClass:"content-area"},[_vm._m(4),_c('div',{staticClass:"img-row"},[_c('vue-qr',{ref:"qrCode",attrs:{"text":_vm.appUrl,"logoScale":40}})],1)]),_c('div',{staticClass:"qr-btn-mask"})]),_c('div',{staticClass:"btn-2 btn-box qr-btn-box"},[_c('div',{staticClass:"content-area"},[_vm._m(5),_c('div',{staticClass:"img-row"},[_c('vue-qr',{ref:"qrCode",attrs:{"text":_vm.appUrl,"logoScale":40}})],1)])]),_c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.resize),expression:"resize"}],ref:"buttonPresident",staticClass:"btn-3 btn-box common-btn-box",on:{"click":function($event){return _vm.download('win')}}},[_vm._m(6)]),_c('div',{staticClass:"btn-4 btn-box common-btn-box",on:{"click":function($event){return _vm.download('mac')}}},[_vm._m(7)])]),_c('div',{staticClass:"instructions-btn-area common-target",on:{"click":_vm.scrollToIns}},[_vm._m(8)]),_c('img',{staticClass:"desktop-backgroud",attrs:{"src":"/appinstall/pictures/desktop-back-1.png"}})]),_c('div',{ref:"pageSection2",staticClass:"desktop-back-2"},[_c('div',{staticClass:"title-area-2 common-target"},[_vm._v("应用安装说明")]),(_vm.isShow)?_c('div',{staticClass:"tab-row common-target"},[_c('div',{staticClass:"tab-inner"},[_c('div',{class:_vm.active === 1 ? 'tab-selected-item' : 'tab-item',on:{"click":function($event){return _vm.tabClick(1)}}},[_c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":_vm.active === 1 ?
                '/appinstall/pictures/android-logo-active.png' :
                '/appinstall/pictures/android-logo-dark.png'}}),_c('div',{staticClass:"tip-content"},[_vm._v(" Android ")])]),_vm._m(9)]),_c('div',{class:_vm.active === 2 ? 'tab-selected-item' : 'tab-item',on:{"click":function($event){return _vm.tabClick(2)}}},[_c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":_vm.active === 2 ?
                '/appinstall/pictures/ios-logo-active.png' :
                '/appinstall/pictures/ios-logo-dark.png'}}),_c('div',{staticClass:"tip-content"},[_vm._v(" iOS ")])]),_vm._m(10)]),_c('div',{class:_vm.active === 3 ? 'tab-selected-item' : 'tab-item',on:{"click":function($event){return _vm.tabClick(3)}}},[_c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":_vm.active === 3 ?
                '/appinstall/pictures/win-logo-active.png' :
                '/appinstall/pictures/win-logo-dark.png'}}),_c('div',{staticClass:"tip-content"},[_vm._v(" Windows ")])]),_vm._m(11)]),_c('div',{class:_vm.active === 4 ? 'tab-selected-item' : 'tab-item',on:{"click":function($event){return _vm.tabClick(4)}}},[_c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":_vm.active === 4 ?
                '/appinstall/pictures/ios-logo-active.png' :
                '/appinstall/pictures/ios-logo-dark.png'}}),_c('div',{staticClass:"tip-content"},[_vm._v(" macOS ")])]),_vm._m(12)])])]):_vm._e(),(_vm.isShow)?_c('div',{staticClass:"tab-area common-target"},[_c('instruction',{attrs:{"index":_vm.active},on:{"indexChange":_vm.indexChange}})],1):_vm._e(),_vm._m(13),_vm._m(14),_c('img',{staticClass:"desktop-backgroud",attrs:{"src":"/appinstall/pictures/desktop-back-2.png"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-header common-target"},[_c('div',{staticClass:"logo-1-row"},[_c('img',{attrs:{"src":"/appinstall/pictures/header-logo-1.png"}}),_c('div',{staticClass:"divider"})]),_c('div',{staticClass:"logo-2-row"},[_c('div',{},[_c('img',{attrs:{"src":"/appinstall/pictures/header-logo-2.png"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-area-1 common-target"},[_c('img',{attrs:{"src":"/appinstall/pictures/show-area-1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo-area"},[_c('img',{attrs:{"src":"/appinstall/pictures/title-logo.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-content common-target"},[_c('img',{attrs:{"src":"/appinstall/pictures/slogan-1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":"/appinstall/pictures/android-logo.png"}}),_c('div',[_vm._v("Android")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":"/appinstall/pictures/ios-logo.png"}}),_c('div',[_vm._v("iOS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":"/appinstall/pictures/win-logo.png"}}),_c('div',[_vm._v("Windows")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip-row"},[_c('img',{attrs:{"src":"/appinstall/pictures/ios-logo.png"}}),_c('div',[_vm._v("macOS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner-instructions-btn"},[_c('div',{staticClass:"tip-row"},[_vm._v("安装说明")]),_c('div',{staticClass:"img-row"},[_c('img',{staticClass:"desktop-backgroud-1",attrs:{"src":"/appinstall/pictures/arrow.png"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-row"},[_c('div',{staticClass:"line-target"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-row"},[_c('div',{staticClass:"line-target"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-row"},[_c('div',{staticClass:"line-target"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"line-row"},[_c('div',{staticClass:"line-target"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact-row common-target"},[_c('div',{staticClass:"content-row"},[_vm._v("技术支持：itsiiri@siiri.cn")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rights-row common-target"},[_c('div',{staticClass:"content-row"},[_vm._v("Copyright 2023 ©国机工业互联网研究院有限公司. All rights reserved.")])])
}]

export { render, staticRenderFns }